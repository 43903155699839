export default class JsonApiError extends Error {
  public status: string;

  public detail: string | undefined;

  public code: string | undefined;

  constructor(
    body: {status: string, title: string, detail?: string, code?:string},
    options: {cause?: Error},
  ) {
    super(body.title, { cause: options.cause });

    this.status = body.status;
    this.detail = body.detail;
    this.code = body.code;
  }
}
