/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-use-before-define */
import Response from '@/network/response';
import { AxiosError } from 'axios';
import {
  JsonApiData,
  JsonApiRelationshipData,
  Json,
} from '@/api/types';
import { singularize, isSingular } from '@/plugins/singularize';
import JsonApiError from '@/api/jsonapi/JsonApiError';

/**
 * Helper class that defines a 'visited' type. Used to prevent endless recursion of relationships.
 */
class Visited {
  type: string;

  id: string;

  constructor(t: string, i: string) {
    this.type = singularize(t);
    this.id = i;
  }
}

// Allows you to map combined API types to real API types
// This is necessary, since in the included array, there will be no type: 'childDevices'
const TYPE_MAPPING: any = {
  childReservations: 'reservations',
  parentReservation: 'reservations',
  childDevices: 'devices',
  parentDevices: 'devices',
  blockRm: 'users',
  unblockRm: 'users',
};

/**
 * Searches the given includes for the type and id.
 * @param type The type of the relationship
 * @param id The id of the relationship
 * @param includes The entire includes array
 * @return object|undefined Returns a matching object or undefined if none could be found.
 */
function getMatchingObjectFromIncludes(
  type: string,
  id: string,
  includes: Array<JsonApiData<string, Record<string, Json>, string>>,
) {
  // Filter out all objects that don't match
  const relevantObjects = includes.filter((el) => {
    const typeMatches = el.type === type || singularize(el.type) === type;
    return typeMatches && `${el.id}` === id
  });

  // If none is remaining, return undefined
  if (relevantObjects.length === 0) {
    return undefined;
  }

  // Otherwise, return the one that is still left
  // (per definition of json:api only one should remain)
  return relevantObjects[0];
}

/**
 * Reads the relationships one by one. Checks the includes for matching entries and builds
 * a complete relationship object out of it.
 * @param objectData The data for this object
 * @param includes The full includes array
 * @param visited A list of strings that contain the singular types that were already visited in
 * this execution path, to prevent endless recursion
 * @return object The object that represents the relationship for the given objectData
 */
function parseRelationships(
  objectData: JsonApiData<string, Record<string, Json>, string>,
  includes: Array<JsonApiData<string, Record<string, Json>, string>> | undefined,
  visited: Array<Visited>,
) {
  const result: Record<string, any> = {};

  // Process each relationship of objectData individually
  Object.entries(objectData.relationships ?? [])
    .forEach((entry) => {
      const key = entry[0];
      const value = entry[1];

      // If the data attribute inside this relationship is non-existent, we set
      // null for this relationship
      if (value.data === undefined
        || value.data === null
        || (Array.isArray(value.data) && value.data.length === 0)
      ) {
        result[key] = undefined;
        return;
      }

      // Now we know that the relationship contains data
      const processedData = processData(value.data, includes, visited);

      // This is a safety measure, just in case the if-statement above didn't catch everything.
      if (processedData.length <= 0) {
        return;
      }

      const data = isSingular(key) && processedData.length === 1 ? processedData[0] : processedData;

      // If the includes are not defined, we don't need to proceed
      if (includes === undefined) {
        result[key] = data;
        return;
      }

      // Check if there is a type mapping, such as childReservations => reservations
      const realType = Object.keys(TYPE_MAPPING)
        .includes(key) ? TYPE_MAPPING[key] : key;

      // Helper method to get the include for one item and merge it with the existing object
      const processSingleItem = (item: any, type: string) => {
        const matchingInclude = getMatchingObjectFromIncludes(type, item.apiId, includes);
        if (matchingInclude === undefined) {
          return undefined;
        }
        const visitedClone = [...visited];
        const d = processData(matchingInclude, includes, visitedClone);
        if (d.length <= 0) {
          return undefined;
        }
        return { ...item, ...d[0] };
      }

      // Differentiate between the cases where there is only one or multiple relationships
      if (Array.isArray(data)) {
        result[key] = data.map(
          (relationship) => processSingleItem(relationship, realType),
        );
      } else if (typeof data === 'object' && data !== null) {
        result[key] = processSingleItem(data, realType);
      } else {
        throw new Error('The relationship object had an unexpected type.');
      }
    });

  return result;
}

/**
 * Checks if the given 'visited' array already contains the type and id
 * @param visited The array of 'visited' objects
 * @param type
 * @param id
 */
function visitedIncludesTypeAndId(visited: Array<Visited>, type: string, id: string) {
  const singularizedType = singularize(type);
  const visitedMatches = visited.filter(
    (v) => v.type === singularizedType && v.id === id,
  );

  if (visitedMatches.length <= 0) {
    return false;
  }

  return true;
}

/**
 * @param objectData An object of form { type, id, attributes, ?relationships, ?links }
 * @param includes The entire includes object
 * @param visited A list of strings containing the singular types that were already parsed in this
 * execution path, to prevent endless recursion
 * @return object The entire object, containing the attributes,
 * as well as relationships with included data
 */
function parseObject(
  objectData: JsonApiData<string, Record<string, Json>, string> | JsonApiRelationshipData,
  includes: Array<JsonApiData<string, Record<string, Json>, string>> | undefined,
  visited: Array<Visited>,
): any {
  const otherKeys = Object.keys(objectData)
    .filter((key) => !['type', 'id'].includes(key));
  const visitedClone = [...visited];
  // In this case, the objectData is not a relationship object, so add it to visited
  if (otherKeys.length >= 1) {
    if (visitedIncludesTypeAndId(visited, objectData.type, objectData.id)) {
      return {};
    }
    visitedClone.push(new Visited(objectData.type, objectData.id));
  }

  const keys = Object.keys(objectData);

  let result: any = {
    apiId: objectData.id,
    apiLinks: objectData.links,
    apiType: objectData.type,
  };

  if ('attributes' in objectData) {
    result = { ...result, ...objectData.attributes };
  }

  if ('relationships' in objectData) {
    const relationships = parseRelationships(objectData, includes, visitedClone);
    result = { ...result, ...relationships };
  }

  return result;
}

/**
 * Processes the given data. Differentiates between data arrays and objects
 * @param data The entire data object, as returned by an API response
 * @param includes The unmodified included array
 * @param visited A list of strings containing the singular types that were already parsed in this
 * execution path, to prevent endless recursion
 */
function processData(
  data: JsonApiData<string, Record<string, Json>, string>
    | JsonApiData<string, Record<string, Json>, string>[]
    | JsonApiRelationshipData
    | Array<JsonApiRelationshipData>,
  includes: Array<JsonApiData<any, any, any>> | undefined,
  visited: Array<Visited>,
): Array<any> {
  if (data === null) {
    return [];
  }

  if (Array.isArray(data)) {
    return data.map((el) => {
      const visitedClone = [...visited];
      return parseObject(el, includes, visitedClone);
    });
  }

  if (typeof data === 'object') {
    return [parseObject(data, includes, visited)];
  }

  throw Error('The data of the response had an unexpected type.');
}

/**
 * Parses the JSON API response from its original form to a more shallow
 * form without the boilerplate data
 *
 * For example, if you get a response like this:
 * ```json
 * {
 *     "data": {
 *         "type": "reservations",
 *         "id": "1",
 *         "attributes": { "start": "2023-10-13T13:05:00Z" },
 *         "relationships": {
 *             "device": {
 *                 "data": {
 *                     "type": "devices",
 *                     "id": "2"
 *                 }
 *             }
 *         },
 *         "links": {
 *             "self": "some/link"
 *         }
 *     },
 *     "included": [
 *         {
 *             "type": "devices",
 *             "id": "2",
 *             "attributes": { "name": "Device 1" }
 *         }
 *     ]
 * }
 * ```
 *
 * The parsed object would contain the attributes of the reservation as
 * top level attributes, as well as the devices associated with it as
 * another attribute like this:
 *
 * ```json
 * {
 *     "apiId": "1",
 *     "apiLinks": {
 *         "self": "some/link"
 *     },
 *     "apiType": "reservations",
 *     "start": "2023-10-13T13:05:00Z",
 *     "device": {
 *         "apiId": "2",
 *         "apiType": "devices",
 *         "name": "Device 1"
 *     }
 * }
 * ```
 *
 * @param response The original response as returned by the server
 * @return object An object that contains all the data of the original response,
 * but in a more shallow format
 */
export default function parseJsonApiResponse(
  response: Response<any>,
): any[] {
  if (response.status > 202) {
    throw Error(`A json:api response with status ${response.status} can't be parsed.`);
  }

  const body = response.data;
  const {
    data,
    included,
  } = body;

  // This will start the processing chain.
  // For each data object this will process the attributes.
  // After that it will process all relationships recursively until none are left.
  // It also checks for potential endless recursions such as author -> article -> author
  // In that case the deeper author object won't contain the article
  // anymore to prevent further recursion
  return processData(data, included, []);
}

/**
 * Starts the processing chain using the given json object. Expects the given jsonBody to be a valid
 * {json:api} response from the backend, or at least in the same format.
 * @param jsonBody The data that should be parsed
 */
export function parseJsonApiData(jsonBody: any): Array<object> {
  const { data, included } = jsonBody;

  // This will start the processing chain.
  // For each data object this will process the attributes.
  // After that it will process all relationships recursively until none are left.
  // It also checks for potential endless recursions such as author -> article -> author
  // In that case the deeper author object won't contain the article
  // anymore to prevent further recursion
  return processData(data, included, []);
}

/**
 * Returns
 * @param error
 */
export function parseJsonApiError(error: Error) {
  if (!(error instanceof AxiosError)) {
    return error;
  }

  if (!error.response) {
    return error;
  }

  if (!error.response.headers['content-type']
    || error.response.headers['content-type'] !== 'application/vnd.api+json') {
    return error;
  }

  const body = error.response.data;
  const { errors } = body;
  if (!errors || errors.length === 0) {
    return error;
  }

  return new JsonApiError({
    ...errors[0],
    status: errors[0].status ?? error.response.status,
  }, { cause: error });
}
