export function singularize(word: string) {
  const endings: any = {
    ves: 'fe',
    ies: 'y',
    i: 'us',
    zes: 'ze',
    ses: 's',
    es: 'e',
    s: '',
  };
  return word.replace(
    new RegExp(`(${Object.keys(endings).join('|')})$`),
    (r: string) => endings[r],
  );
}

export function isSingular(word: string) {
  return singularize(word) === word;
}
